.grid-wrapper {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.grid-row {
    display: flex;
    gap: 12px;
    margin-bottom: 8px;
}

.grid-item {
    flex: 1;
    min-width: 0;
}

.grid-item h3 {
    margin-bottom: 4px;
    font-size: 1rem;
}

.grid-item select {
    width: 100%;
    padding: 6px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

@media (max-width: 600px) {
    .grid-row {
        flex-direction: column;
        gap: 10px;
    }
}

.profile-action-container {
    margin-top: 20px;
    text-align: center;
}

.sign-in-button {
    background-color: #4285f4;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.sign-in-button:hover {
    background-color: #357abd;
}

.save-profile-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
}

.save-profile-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.save-profile-button:hover:not(:disabled) {
    background-color: #45a049;
}

.sign-in-message {
    text-align: center;
    color: #666;
    margin-top: 20px;
    padding: 10px;
    background-color: #f8f8f8;
    border-radius: 4px;
}

.google-signin-button {
    display: block;
    width: 100%;
    max-width: 300px;
    margin: 20px auto;
    padding: 12px 24px;
    background-color: #4285f4;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    text-align: center;
}

.google-signin-button:hover {
    background-color: #357abd;
}