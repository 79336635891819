:root {
    --primary-color: #0066ff;
    --background-color: #f5f5f5;
    --card-background: #ffffff;
    --text-color: #333333;
    --border-radius: 16px;
}

body {
    background-color: var(--background-color);
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    box-sizing: border-box;
}

.container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    background-color: var(--card-background);
    border-radius: var(--border-radius);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-sizing: border-box;
    min-height: 70vh;
    position: relative;
}

.header {
    margin-bottom: 32px;
    text-align: center;
    width: 100%;
}

.title {
    font-size: 32px;
    font-weight: bold;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.logo {
    font-size: 28px;
}

.subtitle {
    color: #666;
    margin: 8px 0 20px 0;
    font-size: 16px;
}

.gender-selection {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 20px 0;
    flex-wrap: wrap; /* Ensures wrapping on smaller screens */
}

.radio-label {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px 20px;
    border: 2px solid #ddd;
    border-radius: 20px;
    transition: all 0.3s ease;
    font-size: 16px;
    min-width: 120px; /* Consistent button size */
    text-align: center;
}

.radio-label:hover {
    background-color: #f5f5f5;
}

.radio-label input[type="radio"] {
    display: none; /* Hide default radio input */
}

/* Style for selected radio button */
.radio-label input[type="radio"]:checked + span {
    border-color: #007bff;
    background-color: #e3f2fd;
    color: #007bff;
    font-weight: bold;
}

.textarea {
    width: 100%;
    height: 120px;
    padding: 16px;
    border: 1px solid #ddd;
    border-radius: 12px;
    margin-bottom: 24px;
    font-size: 16px;
    resize: none;
    box-sizing: border-box;
}

.textarea:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.4);
}

.generate-button {
    background-color: var(--primary-color);
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    margin-top: 20px;
    transition: background-color 0.3s, transform 0.2s;
}

.generate-button:hover {
    background-color: #0056cc;
    transform: translateY(-2px);
}

.generate-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

/* Responsive styles */
@media (max-width: 768px) {
    .container {
        padding: 20px;
        max-width: 90%;
    }

    .title {
        font-size: 28px;
    }

    .subtitle {
        font-size: 14px;
    }

    .gender-selection {
        gap: 10px;
    }

    .radio-label {
        padding: 8px 16px;
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .title {
        font-size: 24px;
    }

    .subtitle {
        font-size: 12px;
    }

    .generate-button {
        font-size: 14px;
    }
}

/* Modal styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    width: 90%;
    max-height: 90vh;
    overflow-y: auto;
    text-align: center;
    position: relative;
}

.close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    font-size: 24px;
    font-weight: normal;
    cursor: pointer;
    padding: 5px;
    color: #666;
    z-index: 1000;
    transition: all 0.2s ease;
}

.close-button:hover {
    color: #333;
}