.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
}

.close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0.5rem;
}

.outfit-title {
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
    color: #333;
}

.modal-image {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 2rem;
}

.image-loading {
    text-align: center;
    padding: 2rem;
    background: #f5f5f5;
    border-radius: 8px;
    margin-bottom: 2rem;
}

.outfit-cards-container {
    background: #f5f5f5;
    padding: 20px;
    border-radius: 12px;
    margin-top: 20px;
}

.outfit-cards-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    max-width: 1000px;
    margin: 0 auto;
}

.outfit-card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.card-header {
    background: #f8f9fa;
    padding: 15px;
    border-bottom: 1px solid #eee;
}

.card-header h4 {
    margin: 0;
    color: #333;
    font-size: 1.2rem;
    text-align: center;
}

.card-content {
    padding: 15px;
}

.card-description {
    color: #666;
    line-height: 1.6;
    margin: 0 0 15px 0;
}

.card-footer {
    background: #007bff;
    color: white;
    padding: 12px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-footer span {
    flex: 1;
    margin-right: 10px;
    font-size: 0.9rem;
}

.shop-button {
    background: white;
    color: #007bff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s;
    display: inline-flex;
    align-items: center;
    gap: 8px;
}

.shop-button:hover {
    background: #f8f9fa;
    transform: translateY(-1px);
}

/* Optional: Add an icon */
.shop-button::after {
    content: "→";
    font-size: 1.1em;
}

@media (max-width: 768px) {
    .outfit-cards-grid {
        grid-template-columns: 1fr;
    }
}

.outfit-grid-container {
    width: 100%;
    padding: 25px;
    margin: 20px 0;
}

.outfit-grid {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 25px !important;
    width: 100% !important;
}

.outfit-grid-item {
    background: white !important;
    border-radius: 12px !important;
    padding: 25px !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
}

.outfit-grid-item h4 {
    font-size: 1.3rem;
    color: #1a202c;
    margin: 0;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 2px solid #e2e8f0;
    text-align: center;
    font-weight: 600;
}

.outfit-grid-item p {
    margin: 0 0 15px 0 !important;
    line-height: 1.7;
    color: #4a5568;
    font-size: 0.95rem;
    padding: 0 5px;
}

.short-desc {
    background: #007bff;
    color: white;
    padding: 12px 16px;
    border-radius: 8px;
    font-size: 0.9rem;
    text-align: center;
    box-shadow: rgba(0, 123, 255, 0.2) 0px 2px 4px;
}

/* Make buttons in the same row align */
.outfit-grid > *:nth-child(-n+2) {
    display: flex !important;
    flex-direction: column !important;
}

.outfit-grid > *:nth-child(n+3) {
    display: flex !important;
    flex-direction: column !important;
}

@media (max-width: 768px) {
    .outfit-grid {
        grid-template-columns: 1fr !important;
        gap: 20px !important;
    }

    .outfit-grid-item {
        min-height: 250px !important;
    }
}
